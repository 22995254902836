import React from "react"
import styled from "styled-components";
import ContactForm from "../../components/landing/ContactForm";
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import imgCloud from "../../images/source/imagen_cloud.png"
import background from "../../images/source/fondo_cloud.png"
import background_small from "../../images/source/fondo_cloud_small.png"

const Cloud = ({ location }) => (
  <Layout location={location}>
    <SEO title="Cloud Services" />
    <Styled>
      <div className="info">
        <h3>CLOUD SERVICES</h3>
        <h4>Operation & Migration</h4>
        <p>Expertos en altas cargas de trabajo a la Nube, desde su migración hasta gestión manutención y optimización.</p>
        <img width="350" height="196" src={imgCloud}  alt="img" />

        <div className="background">
          <div className="text">
            <ul>
              <li>Servicios profesionales multicloud.</li>
              <li>Todas las nubes, especialistas en Azure y AWS.</li>
              <li>Creación de un proceso en fases adaptables.</li>
              <li>Especialistas en la planificación y realización de grandes migraciones exitosas.</li>
              <li>Análisis de costos de la infraestructura existente, para proponer el mejor escenario posible.</li>
              <li>Partnership con principales vendors.</li>
              <li>Alto nivel de acompañamiento, eficiencia y calidad operativa.</li>
            </ul>
          </div>
        </div>
      </div>
      <div className="contact">
        <ContactForm  page="Cloud Services" location={location} 
        title={'Elíge tu nube. Luego, elígenos.'} 
        paddingTitle='0px' 
        title2={'Contáctanos para conocer nuestros casos de éxito y experiencias en  soluciones cloud para empresas como la tuya.'} />
      </div>
    </Styled>
  </Layout>
)

const Styled = styled.div`
height: 100%;
min-height: 800px;
padding-top: 60px;
display:flex;
background-image: url("${background}");
flex-wrap: wrap;
flex-direction: row;
justify-content: center;
background-size: cover;
background-position-y: -90px;
background-repeat-y: no-repeat;

@media (max-width: 1100px) {
    background-position-y: 30px;
    background-repeat: no-repeat;
    background-position-x: -410px !important;
}

@media (max-width: 1300px) {
    background-position-y: 30px;
    background-repeat: no-repeat;
    background-position-x: -310px !important;
}

@media (max-width: 1600px) {
    background-position-y: 30px;
    background-repeat: no-repeat;
    background-position-x: -210px;
}

@media (max-width: 780px) {
  flex-direction: column-reverse;
  background: none;
  padding-top: 20px;
}

@media (min-width: 780px) and (max-width: 1050px){
  height: 56%;
}

.info {

    margin-top: 60px;
    @media (max-width: 780px) {
      margin-top: 20px;
    }

    iframe {
      @media (max-width: 780px) {
          width: 375px;
          height: 220px;
          margin-bottom: 0px !important;      
      }
    }

    @media (max-width: 780px) {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    h3 {
        height: 40px;
        width: 350px;
        color: #136FB7;
        font-family: "Century Gothic";
        font-size: 38px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 50.4px;
        margin-bottom: 10px;
    }
    
    h4{
      height: 33px;
      width: 279px;
      color: #136FB7;
      font-family: "Century Gothic";
      font-size: 18px;
      font-weight: bold;
      letter-spacing: 0.75px;
      line-height: 28.8px;
      margin:0px;
      @media (max-width: 780px){
        width: 348px;
      }

    }

    p {
        height: 50px;
        width: 350px;
        color: #000000;
        font-family: "Century Gothic";
        font-size: 15px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 19px;
    }

    iframe {
      margin-bottom: 15px;
    }

    .text {
        margin-top: 10px;
        height: 192px;
        width: 350px;
        color: #FFFFFF;
        font-family: "Century Gothic";
        font-size: 18px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 32px;

        ul {

          list-style: none;
          position: relative;
          left: -20px;

          li {
            width: 350px;
            color: #FFFFFF;
            font-family: "Century Gothic";
            font-size: 14px;
            font-weight: bold;
            letter-spacing: 0;
            line-height: 20px;

            &:before {
              content: "•";
              color: #E3DE1E;
              font-weight: bold;
              display: inline-block;
              width: 1em;
              margin-left: -1em;
            }
          }
          
        }

                
        @media (max-width: 780px) {
          margin: 0 auto;
          height: 420px;
        }
    }

    .background {
      @media (max-width: 780px) {
            padding-top: 20px;
            width: 100%;
            background-image: url("${background_small}");
            background-size: contain;
        }
    }

}

.title2{
  height: 40px;
  width: 500px;
  color: #000000;
  font-family: Questrial;
  font-size: 15px;
  letter-spacing: 0;
  line-height: 19px;
  text-align: center;
  padding-top: 10px;
  @media (max-width: 780px){
    width: 300px;
    text-align: start;
  }


}
.title{
  @media (max-width: 780px){
    text-align: start;
    padding: 0px;
  }
}

.linkAccess{
  @media (max-width: 780px){
    margin-top: 40px;
  }
}

.contact {
    margin-top: 70px;
    padding: 40px;
    height: 588px;
    width: 573px;
    border-radius: 8px;
    background-color: #FFFFFF;
    box-shadow: 0 2px 10px 0 rgba(0,0,0,0.2);
    margin-left: 110px;

    @media (max-width: 1050px){
      margin: 100px 0px 20px 0px;;

    }

    @media (max-width: 900px) {
        margin-left: 0px;
    }

    @media (max-width: 780px) {
      box-shadow: none;
      border-radius: 0;
      margin-top: 0px;
      padding: 20px;
      width: 100%;
    }
}
`


export default Cloud;